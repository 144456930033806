import React from 'react';
import { createPortal } from "react-dom";
import { useSelector, useDispatch } from 'react-redux';
import './BannerPopUp.scss'

import { setBannerPopUp } from '../../store/bannerPopUp/bannerPopUp.slice';
import closeLogin from '../../assets/images/closeLogin.svg'

const modalRoot = document.querySelector("#modal-root");

const BannerPopUp = () => {
  const dispatch = useDispatch()
  const banners = useSelector(state => state.promotions)

  return createPortal(
    ( banners?.afterLoginPopup[0] && <div className="BannerPopUp">
      <div className="BannerPopUp_wrapper" onClick={(e) => {
          if (e.currentTarget === e.target) {
            dispatch(setBannerPopUp(false))
          }
        }}>
        <div className="BannerPopUp">
          <div className="BannerPopUp-content">
            <img className='BannerPopUp-content__image' src={banners?.afterLoginPopup[0]?.image} alt=''
                 onClick={() => {
                   if (banners?.afterLoginPopup[0]?.redirect && banners?.afterLoginPopup[0]?.redirect !== "") {
                     if (banners?.afterLoginPopup[0]?.redirect?.startsWith("http")) {
                       window.open(`${banners?.afterLoginPopup[0]?.redirect}`)
                     }
                   }
                 }} />
            <img className='closePopUpButtonn' src={closeLogin} alt='' onClick={() => dispatch(setBannerPopUp(false))}/>
          </div>
        </div>
      </div>
    </div>),
    modalRoot
  )
}

export default BannerPopUp
