import { getLastResultsExchSuccess, getLastResultsExchError } from './lastResultsExch.slice'
import axios from 'axios'
import getErrorMessage from '../../helpers/getErrorMessage.helper'

export const requestLastResultsExch = (gameId) => async (dispatch) => {
        return await axios
          .get(`https://lgodds.route2222.com/result/past_result?gameId=${gameId}`)
          .then((response) => dispatch(getLastResultsExchSuccess(response.data.result)))
          .catch((e) =>{
            dispatch(getLastResultsExchError())
            const errorMsg = getErrorMessage(e)
            throw errorMsg})
        }
      

    // try {
    //     const response = axios
    //     .get(`https://lgodds.route2222.com/result/past_result?gameId=${gameId}`)
    //    .then (response) {
    //        console.log('response.data.success', response.data.success)
    //         dispatch(getLastResultsExchSuccess(response.data.result))
    //     }
    //     return response
    // } catch (e) {
    //     dispatch(getLastResultsExchError())
    //     const errorMsg = getErrorMessage(e)
    //     throw errorMsg
    // }
