import { IonButton, IonIcon } from '@ionic/react'
import { useSelector, useDispatch } from 'react-redux'
import React from 'react'
import { useHistory } from 'react-router'
import { setLoginNew } from '../../../../store/LoginNew/loginNew.slice'
import { setUrlForSportbook } from '../../../../store/betbyWidget/urlForSportbook.slice'
import { requestLiveCasinoID } from '../../../../store/liveCasino/liveCasino.thunks'
import { setQuickButtonSlots } from '../../../../store/slotsGames/slotsGames.slice'
import { setSlotCasinoPopUp } from '../../../../store/slotCasinoPopUp/slotCasinoPopUp.slice'



const QuickButton = ({ name, icon, redirect, backgroundImage, backgroundColour, isSlide, id }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const auth = useSelector((state) => state.auth)

  const gotoEvent = () => {
        // if(auth || name === "Sportsbook") {
        //     history.push(redirect)
        // } else {
        //     dispatch(setLoginNew(true))
        // }

    if (redirect.split('=')[1]) {
      dispatch(setUrlForSportbook(redirect.split('=')[1]))
    } else {
      dispatch(setUrlForSportbook(null))
    }

    if (name.indexOf('Evolution') != -1){
      dispatch(setSlotCasinoPopUp(true))
      dispatch(requestLiveCasinoID(redirect))
    } else if (redirect.indexOf('slotsgames') != -1) {
      history.push("/slotsgames-lobby")
      dispatch(setQuickButtonSlots(redirect.slice(redirect.lastIndexOf('/') + 1)))
      dispatch(setSlotCasinoPopUp(true))
    } else if (redirect.indexOf('livecasino') != -1) {
      history.push("/livecasino-lobby")
      dispatch(setSlotCasinoPopUp(true))
    } else {
      history.push(redirect)
    }


    if(!auth && name.indexOf('Evolution') != -1) {
        dispatch(setLoginNew(true))
    } else if (redirect.indexOf('slotsgames') != -1) {
      history.push("/slotsgames-lobby")
      dispatch(setQuickButtonSlots(redirect.slice(redirect.lastIndexOf('/') + 1)))
    } else if (redirect.indexOf('livecasino') != -1) {
      history.push("/livecasino-lobby")
      dispatch(setQuickButtonSlots(redirect.slice(redirect.lastIndexOf('/') + 1)))
    } else {
      history.push(redirect)
      }
    }
  return (
        <IonButton className="promotion-button" mode="md" id={id}>
            <div className="promotion-button__content"
                onClick={isSlide ? () => {} : () => {
                  gotoEvent()
                  console.log('asd', name);
                }}
                style={{
                  backgroundImage: `url(${backgroundImage})`,
                  backgroundColor: `${backgroundColour}`
                }}
            >
               <div className="promotion-button__icon" style={{ backgroundImage: `url(${icon})`}}></div>
               {name}
            </div>
        </IonButton>
    )
}

export default QuickButton
