import React from 'react'
import './EventHeader.scss';
import { getFormattedDate, timeOpens } from '../../helpers/date.helper'
import { useHistory, useParams } from "react-router-dom";
import infoIcon from '../../assets/images/info_icon.svg'
import {
  IonIcon,
} from '@ionic/react'
import { connectEventHeader } from './EventHeader.connect'

const statuses = {
  "InPlay": "In play",
  "BetweenInnings": "Innings Break",
  "Review": "REVIEW",
  "Drinks": "Drinks",
  "Rain": "Rain",
  "BadLight": "Bad light stopped play",
  "Injury": "Injury timeout",
  "Other": "Delay",
}

const EventHeader = ({
  sportStats,
  sportData,
  raceOdds,
  timeSettings,
  actions
}) => {
  const history = useHistory()
  const { eventTypeId, eventId } = useParams()

  const isRacing = eventTypeId == 7 || eventTypeId == 4339
  const startTime = (isRacing) ? raceOdds?.[`${eventTypeId} ${eventId}`]?.start : sportData?.['0']?.start
  const now = Date.now()
  const startDate = new Date(startTime)
  const formattedOpenDate = getFormattedDate(startTime, timeSettings.timeZoneName)

  const eventName = () => {
    if (history.location.pathname.indexOf(`/scorecard/${eventTypeId}/${eventId}`) !== -1) {
      return `${sportStats?.scorecard?.awayTeam?.name} v ${sportStats?.scorecard?.homeTeam?.name}`
    }
    if (isRacing)
      if (raceOdds) {
        return raceOdds[`${eventTypeId} ${eventId}`]?.event?.name.replace(/\${HH:mm}/, formattedOpenDate.time)
      }
    if (sportData && sportData[0]) {
      if (sportStats?.stats?.selections?.length > 1) {
        return <>
          {sportStats?.stats?.selections.map((item, index) => <div className="EventHeader__details__main__title__item" key={index}>{item.name}</div>)}
        </>
      }
      if (sportStats?.stats?.teams?.length > 1) {
        return <>
          {sportStats?.stats?.teams.map((item, index) => <div className="EventHeader__details__main__title__item" key={index}>{item.name}</div>)}
        </>
      }
      return sportData[0]?.event?.name
    }
    if (sportStats?.scorecard) {
      return `${sportStats.scorecard.awayTeam?.name} v ${sportStats.scorecard.homeTeam?.name}`
    }
  }

  const rulesLink = () => {
    if (eventTypeId === '1444001'){
      actions.setRulesPopUp(true)
    }
    else if (isRacing) return `/rules_page/${eventTypeId}/${eventId}`
    else return `/rules_page/${eventTypeId}/${sportData?.['0']?.competition?.id}`
  }

  const eventIcon = () => {
    if (sportStats?.stats?.time) return <div className="EventHeader__details__score__time">{sportStats.stats.time / 60000}'</div>
    if ((eventTypeId == 1 || eventTypeId == 2 || eventTypeId == 4) && (sportData?.['0']?.inPlay || sportStats?.stats?.status == 'InPlay' || sportStats?.stats?.status == 'BetweenInnings')) return <i className="header-icon header-icon--inplay" />
    if (raceOdds?.[`${eventTypeId} ${eventId}`]?.inPlay) return <i className="header-icon header-icon--inplay" />
    if (startTime > now) return <i className="header-icon header-icon--timer" />
    return null
  }

  const eventStartTime = () => {
    if (startTime > now) {
      return <div className="EventHeader__details__additional with-padding">
        {formattedOpenDate.date} {formattedOpenDate.time} {timeOpens(startDate, true)}
      </div>
    }
    return null
  }

  const eventStats = () => {
    if (sportStats?.stats?.battingTeam) {
      const bt = sportStats.stats.battingTeam

      let scoreWicketsMessage = bt.score;
      10 !== bt.wickets && (scoreWicketsMessage += "-" + bt.wickets)

      let r = bt.overs ? bt.overs : 0
      let s = bt.balls ? bt.balls : 0
      const currentOversBalls = r + "." + s

      return (
        <div className="EventHeader__details__additional with-padding">
          {bt.name}: {scoreWicketsMessage} ({currentOversBalls} Ovs)
        </div>
    )}
    return null
  }

  const tossWinnerData = () => {
    const e = sportStats.stats
    let a = e.status,
      i = {
        message: "",
        statusMessage: "",
        statusStyle: "Review" === a ? "review" : ""
      }

    if ("BetweenInnings" === a || 2 === e.currentInning) {
      if ("Completed" !== a) {
        if (null !== e.battingTeam.requiredRuns || e.battingTeam.target) {
          let r = {
            team: e.battingTeam.name,
            requiredScore: null !== e.battingTeam.requiredRuns ? e.battingTeam.requiredRuns : e.battingTeam.target
          }
          /*i.message = (e.battingTeam.name + ' need ' + r.requiredScore + ' runs',
            (null !== e.battingTeam.requiredRuns && e.battingTeam.requiredRuns > 0 && e.remainingBalls <= 60 && (i.message += " from " + e.remainingBalls + " balls")))*/
          i.message = e.battingTeam.name + ' need ' + r.requiredScore + ' runs'
          null !== e.battingTeam.requiredRuns && e.battingTeam.requiredRuns > 0 && e.remainingBalls <= 60 && (i.message += " from " + e.remainingBalls + " balls")
        }
      } else e.result && "Won" === e.result.type && (i.message = 'Match won by ' + e.result.winnerName);
    } else e.tossWinner && e.tossWinner.name && e.tossWinner.decision && (i.message = e.tossWinner.name + " " + (e.tossWinner.decision === 'optToBowl' ? "opt to bowl" : "opt to bat"))

    return (statuses.hasOwnProperty(a) && (i.statusMessage = statuses[a] + (i.message.length ? " - " : "")),
      e.adjustedOvers && "Completed" !== a && i.message.length && (i.message += ' - reduced to ' + (e.oversPerInning || 0) + ' overs'),
      i)
  }

  const tossWinner = () => {
    try {
      if (sportStats?.stats?.tossWinner?.name) {
        const data = tossWinnerData()
        // return <div className="EventHeader__details__additional">{sportStats.stats.tossWinner.name} {sportStats.stats.tossWinner.decision}</div>
        return <div className="EventHeader__details__additional with-padding"><span
          className={`${data.statusStyle}`}>{data.statusMessage}</span> {data.message}</div>
      }
    } catch (e) {
      console.log(e);
      return <></>
    }
  }

  const altName = () => raceOdds?.[`${eventTypeId} ${eventId}`]?.altName ? <div className="EventHeader__details__additional">{raceOdds[`${eventTypeId} ${eventId}`].altName}</div> : null

  const teamsScore = () => sportStats?.stats?.teams
    ? <div className="EventHeader__details__score">
      {sportStats?.stats?.teams.map((item, index) => <div className="EventHeader__details__score__item" key={index}>{item.score}</div>)}
    </div>
    : null



  const tennisStatsSetitem = (item) => {
    if (item || item === 0) return <div className="EventHeader__tennis__row__item EventHeader__tennis__set">{item}</div>
    else return <div className="EventHeader__tennis__row__item EventHeader__tennis__set EventHeader__tennis__set--disabled">0</div>
  }
  const tennisStatsRow = (row) => {
    return <div className="EventHeader__tennis__row" key={row.name}>
      {row.serving
        ? <div className="EventHeader__tennis__row__item EventHeader__tennis__serving">
          <div className="inplay-item__player-batting"></div>
        </div>
        : null
      }
      <div className="EventHeader__tennis__row__item EventHeader__tennis__current">{row.score?.current}</div>
      {tennisStatsSetitem(row.score?.sets?.[0])}
      {tennisStatsSetitem(row.score?.sets?.[1])}
      {tennisStatsSetitem(row.score?.sets?.[2])}
    </div>
  }
  const tennisStats = () => {
    if (eventTypeId == '2' && sportStats?.stats?.selections?.length > 1) {
      return <div className="EventHeader__tennis">
        {sportStats?.stats?.selections.map((item) => tennisStatsRow(item))}
      </div>
    }
  }

  return <div className="EventHeader">
    <div className="EventHeader__details">
      <div className="EventHeader__details__main">
        {eventIcon()}
        {teamsScore()}
        <div className="EventHeader__details__main__title">{eventName()}</div>
      </div>
      <div className="EventHeader__details__additional-container">
        {eventStartTime()}
        {!isRacing && eventStats()}
        {!isRacing && tossWinner()}
        {altName()}
      </div>
    </div>
    {tennisStats()}
    <div className="EventHeader__info">
      <IonIcon className="EventHeader__info__icon" src={infoIcon} onClick={() => history.push(rulesLink())} />
    </div>
  </div>
}

export default connectEventHeader()(EventHeader)
