// import './pop-up.scss'
import React, { useState, useEffect, useRef } from 'react'
import { toastr } from 'react-redux-toastr'
import { useHistory } from 'react-router'
import { IonInput,  IonItem, IonList } from '@ionic/react'
import ReCAPTCHA from 'react-google-recaptcha'
import eye from '../../../assets/images/eye.svg'
import eyeOff from '../../../assets/images/eyeOff.svg'
import closeLogin from '../../../assets/images/closeLogin.svg'
import lotusLogo from '../../../assets/images/lotusLogo.png'
import { connectLoginPopUp } from '../loginPopUp.connect'
import UpdatePopUp from '../../../components/UpdatePopUp/index.js'

const LogInStep = ({  open, onDidDismiss, recaptchaResponseProps, actions, B2C, changeTab, width, focus, setFocus}) => {

  const refRecaptcha = useRef(null)
  const history = useHistory()
  let [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  // useEffect(() => {
  //   if (!auth) {
  //     actions.requestRecaptcha()
  //   }
  // }, [auth])

  const getBalance = (memberCode) => {
    actions
      .requestBalance(memberCode)
      .then((res) => {
       if(B2C && res.data.result.availBalance < 100) {
        // history.push("/deposit")
        }
      })
      .catch((e) => console.log('balance error = ', e))
  }


  const submit = async () => {
    try {
      if (!username || !password || loading) return
      setLoading(true)
      const recaptchaResponse =
      await recaptchaResponseProps.current.executeAsync()
    console.log('recaptchaResponse', recaptchaResponse)
      let newUserName = ''
      if(B2C) {
        newUserName = selectedType == 'mob'? `91_${username}` : username;
        await actions
        .requestAuth({
          username: newUserName,
          password,
          recaptchaResponse,
          answer: '',
          token: '',
        })
        .then((res) => {
          setLoading(false)
          getBalance(res.data.result.memberCode)
          onDidDismiss()
        })
      } else {
        await actions
          .requestAuth({
            username,
            password,
            recaptchaResponse,
            answer: '',
            token: '',
          })
          .then((res) => {
            setLoading(false)
          })
      }
    } catch (e) {
      setLoading(false)
      toastr.error('', `${e}`)
      if (refRecaptcha.current) refRecaptcha.current.reset()
    }
  }

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      submit()
    }
  }

  const changeName = (e) => {
    setUsername(e.detail.value)
  }

  const changePass = (e) => {
    setPassword(e.detail.value)
  }

  // update pop-up
  const [isOpen, setIsOpen] = useState(false)

  // useEffect(() => {
  //   let versionNow = version

  //   console.log(version)

  //   if (version) {
  //     if (localStorage.getItem('versionNow') === versionNow) {
  //       return console.log('version up to date')
  //     } else if (!localStorage.getItem('versionNow')) {
  //       localStorage.setItem('versionNow', versionNow)
  //     } else if (localStorage.getItem('versionNow') != versionNow) {
  //       setIsOpen((isOpen) => !isOpen)
  //       localStorage.setItem('versionNow', versionNow)
  //     }
  //   }
  // }, [version])

  const [show, setShow] = useState(false);
  const [selectedType,selectType] = useState("mob");
  const [showSelection,setSelection] = useState(false);
  // const [focus, setFocus] = useState(false)

  const showHide = () => {
    setShow(!show)
  }
  const onfocusInput = () => {
    setFocus(true);
  };
  const onBlurInput = () => {
    setFocus(false);
  };

  const handleSelect = (type) =>{
    setSelection(false);
    selectType(type);
  }

  return width > 1024 ?
    (
    <>

                  {<div className='loginPopUpContainer'>
                  {B2C ? (
                    <IonList class="login-content__list">
                    <input
                      className='loginInput'
                      formcontrolname="username"
                      // onClick={onfocusInput}
                      placeholder={selectedType == 'mob'?"10 Digit Phone Number":"ID"}
                      name="username"
                      type={selectedType == 'mob' ? 'tel' :'text'}
                      value={`${username}`}
                      autoComplete="username"
                      onChange={(e) => setUsername(e.target.value)}
                      hidden
                    />
                    <input
                      formcontrolname="password"
                      onClick={onfocusInput}
                      className='loginInput'
                      placeholder="Password"
                      name="password"
                      type="password"
                      value={password}
                      autoComplete="current-password"
                      onChange={(e) => setPassword(e.target.value)}
                      hidden
                    />
                      <div className='b2c_login'>
                        <IonItem className='overflow-visible'>
                          {selectedType == 'mob'?<div className='placeholder'>+91</div>:""}
                          <IonInput
                            formcontrolname="username"
                            className={`loginInput ${selectedType == 'user'?"remove-pad-start":""}`}
                            // onClick={onfocusInput}
                            placeholder={selectedType == 'mob'?"10 Digit Phone Number":"ID"}
                            name="username"
                            type={selectedType == 'mob' ? 'tel' :'text'}
                            autoComplete="username"
                            value={`${username}`}
                            onKeyDown={onKeyDown}
                            onIonChange={changeName}
                            onChange={changeName}
                          />
                          <div
                            className={`login_icons`}
                          >
                            <div onClick={()=>setSelection(true)} className={"icon"} >
                                <div className={`selected-icon ${selectedType}`}></div>
                                <div className='arrow-icon'></div>
                              </div>

                            <div className={`option-menu ${showSelection? "show":"hide"}`}>
                              <div onClick={()=>handleSelect('mob')} className={`option-item ${selectedType == 'mob'?"selected":""}`}>
                                  <div className='mob-icon'></div>
                                  <div className='title'>Mobile Number</div>
                              </div>
                              <div onClick={()=>handleSelect('user')} className={`option-item ${selectedType == 'user'?"selected":""}`}>
                                  <div className='user-icon'></div>
                                  <div className='title'>ID</div>
                              </div>
                            </div>
                          </div>
                        </IonItem>

                        <IonItem>
                          <IonInput
                            className='loginInput remove-pad-start'
                            formcontrolname="password"
                            placeholder="Password"
                            // onClick={onfocusInput}
                            name="password"
                            type={show ? "text" : "password"}
                            value={password}
                            autocomplete="current-password"
                            id="current-password"
                            onKeyDown={onKeyDown}
                            onIonChange={changePass}
                            onChange={changePass}
                          />

                          {/*<img className='icons' src={show ? eyeOff : eye }  onClick={showHide} />*/}
                          <div
                            className={`${show ? 'login_icons eyeOff' : 'login_icons eye'} `}
                            onClick={showHide}
                          />
                        </IonItem>
                      </div>

                      </IonList>
                      ): (
                        <IonList class="login-content__list">
                        <input
                          className='loginInput'
                          formcontrolname="username"
                          placeholder="Login Name"
                          // onClick={onfocusInput}
                          name="username"
                          type="username"
                          value={`${username}`}
                          autoComplete="Phone Number"
                          onChange={(e) => setUsername(e.target.value)}
                          hidden
                        />
                        <input
                          className='loginInput'
                          formcontrolname="password"
                          placeholder="Password"
                          // onClick={onfocusInput}
                          name="password"
                          type="password"
                          value={password}
                          autoComplete="current-password"
                          onChange={(e) => setPassword(e.target.value)}
                          hidden
                        />
                        <IonItem>
                          <IonInput
                            className='loginInput'
                            formcontrolname="username"
                            placeholder="Login Name"
                            // onClick={onfocusInput}
                            name="username"
                            type="username"
                            autoComplete="username"
                            value={`${username}`}
                            onKeyDown={onKeyDown}
                            onIonChange={changeName}
                          />
                        </IonItem>
                        <IonItem>
                          <IonInput
                            className='loginInput'
                            formcontrolname="password"
                            placeholder="Password"
                            // onClick={onfocusInput}
                            name="password"
                            type="password"
                            value={password}
                            autoComplete="current-password"
                            onKeyDown={onKeyDown}
                            onIonChange={changePass}
                          />
                        </IonItem>
                      </IonList>
                      )
                    }
                    </div>}
                  {B2C && <div className="pop-up-content__buttons pop-up-content__buttons-new forgot" onClick={() =>  actions.setTab(3)}>Forgot password?</div>}
                  <div className="pop-up-content__buttons">

                    <div
                      onClick={submit}
                      className="pushable"
                      disabled={!username || !password || loading}
                    >
                      <span className="shadow"></span>
                      <span className="edge "></span>
                      <span className="front refreshBtn login">Log in</span>
                    </div>
                  </div>
                  {/* <div className="pop-up-content__buttons sign"><span>New user? </span><a href="https://www.lotus365.com/?sign"> Register now</a></div> */}
    </>
  )
    :
    (<div className={`login-step-wrapper `}>
      <div className="pop-up-content_title" onClick={() => onDidDismiss(false)}>
        <div className="pop-up-content_title-logo"></div>
        <img src={closeLogin} />
      </div>
      <div className="login-step-content">
        <div className="login-step-content__title">Log in</div>
        {<div className='loginPopUpContainer'>
          {B2C ? (
            <IonList class="login-content__list">
              <input
                className='loginInput'
                placeholder={selectedType == 'mob' ?"Phone Number":"ID"}
                // onClick={onfocusInput}
                name="username"
                type={selectedType == 'mob' ? 'tel' :'text'}
                value={`${username}`}
                autoComplete="username"
                onChange={(e) => setUsername(e.target.value)}
                hidden
              />
              <input
                className='loginInput'
                formcontrolname="password"
                placeholder="Password"
                // onClick={onfocusInput}
                onIonBlur={onBlurInput}
                name="password"
                type="password"
                value={password}
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                hidden
              />
              <div className='b2c_login'>
                <IonItem className="login overflow-visible">
                {selectedType == 'mob'?<div className='placeholder'>+91</div>:""}
                  <IonInput
                    className={`loginInput ${selectedType == 'user'?"remove-pad-start":""}`}
                    formcontrolname="username"
                    placeholder={selectedType == 'mob'?"Phone Number":"ID"}
                    // onClick={onfocusInput}
                    onIonBlur={onBlurInput}
                    name="username"
                    type={selectedType == 'mob'? 'tel' :'text'}
                    autoComplete="username"
                    value={`${username}`}
                    onKeyDown={onKeyDown}
                    onIonChange={changeName}
                  />
                <div
                    className={`login_icons`}
                  >
                    <div onClick={()=>setSelection(true)} className={"icon"} >
                        <div className={`selected-icon ${selectedType}`}></div>
                        <div className='arrow-icon'></div>
                      </div>

                    <div className={`option-menu ${showSelection? "show":"hide"}`}>
                      <div onClick={()=>handleSelect('mob')} className={`option-item ${selectedType == 'mob'?"selected":""}`}>
                          <div className='mob-icon'></div>
                          <div className='title'>Mobile Number</div>
                      </div>
                      <div onClick={()=>handleSelect('user')} className={`option-item ${selectedType == 'user'?"selected":""}`}>
                          <div className='user-icon'></div>
                          <div className='title'>ID</div>
                      </div>
                    </div>
                  </div>
                </IonItem>

              <IonItem className="login">
                <IonInput
                  className='loginInput  remove-pad-start'
                  formcontrolname="password"
                  placeholder="Password"
                  // onClick={onfocusInput}
                  onIonBlur={onBlurInput}
                  name="password"
                  type={show ? "text" : "password"}
                  value={password}
                  autoComplete="current-password"
                  onKeyDown={onKeyDown}
                  onIonChange={changePass}
                />
                {/*<img className='icons' src={show ? eyeOff : eye }  onClick={showHide} />*/}
                <div
                  className={`${show ? 'login_icons eyeOff' : 'login_icons eye'} `}
                  onClick={showHide}
                ></div>
              </IonItem>
              </div>
            </IonList>
          ): (
            <IonList class="login-content__list">
              <input
                className='loginInput'
                formcontrolname="username"
                placeholder="Login Name"
                // onClick={onfocusInput}
                onIonBlur={onBlurInput}
                name="username"
                type={ 'text'}
                value={`${username}`}
                autoComplete="Phone Number"
                onChange={(e) => setUsername(e.target.value)}
                hidden
              />
              <input
                className='loginInput'
                formcontrolname="password"
                placeholder="Password"
                // onClick={onfocusInput}
                onIonBlur={onBlurInput}
                name="password"
                type="password"
                value={password}
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                hidden
              />
              <IonItem className="login">
                <IonInput
                  className='loginInput'
                  formcontrolname="username"
                  placeholder="Login Name"
                  // onClick={onfocusInput}
                  onIonBlur={onBlurInput}
                  name="username"
                  type={ 'text'}
                  autoComplete="username"
                  value={`${username}`}
                  onKeyDown={onKeyDown}
                  onIonChange={changeName}
                />
              </IonItem>
              <IonItem className="login">
                <IonInput
                  className='loginInput'
                  formcontrolname="password"
                  placeholder="Password"
                  // onClick={onfocusInput}
                  onIonBlur={onBlurInput}
                  name="password"
                  type="password"
                  value={password}
                  autoComplete="current-password"
                  onKeyDown={onKeyDown}
                  onIonChange={changePass}
                />
              </IonItem>
            </IonList>
          )
          }
        </div>}
        {B2C && <div className="forgot-pass-button" onClick={() =>  actions.setTab(3)}>Forgot password</div>}

        <div className="pop-up-content__buttons">
          <button
            onFocus={submit}
            onClick={submit}
            className="pushable log-in"
            disabled={!username || !password || loading}
          >
            <span className="shadow"></span>
            <span className="edge "></span>
            <span className="front refreshBtn">Log in</span>
          </button>
        </div>
        {B2C && <div className="register-button" onClick={() =>  actions.setTab(2)}>
          {/*<span >New user? </span>*/}
          <span className="register-button-link" >Register now</span>
        </div>}

      </div>
    </div>)
}

export default connectLoginPopUp()(LogInStep)
