import { useSelector, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router"
import Slider from "react-slick";
import './TopBannerSlider.scss'
import { setLoginNew } from '../../../store/LoginNew/loginNew.slice'
import { setUrlForSportbook } from '../../../store/betbyWidget/urlForSportbook.slice'


const TopBannerSlider = ({inPlaySlidingBanners}) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const [isSwipe, setIsSwipe] = useState(false);

  const swipeHandler = () => {
    setIsSwipe(true);
    setTimeout(() => setIsSwipe(false), 500);
  };
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: inPlaySlidingBanners.length,
    slidesToScroll: 1,
    pauseOnHover:false,
    className: "slider variable-width",
    variableWidth: true,
    responsive: [
      {
        breakpoint: 9999,
        settings: {
          autoplaySpeed: 3000,
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  }

  const inPlaySlidingBannersSortForIndex = [...inPlaySlidingBanners].sort(
    (a, b) => (a.index > b.index ? 1 : -1)
  )


  const gotoEvent = (redirect) => {
    if (redirect.split('=')[1]) {
      dispatch(setUrlForSportbook(redirect.split('=')[1]))
    } else {
      dispatch(setUrlForSportbook(null))
    }
    history.push(redirect)
  }

  let bannerList = inPlaySlidingBannersSortForIndex.map((item) => {
    return (
    // item?.name !== '' && (
    <div className="slider_banner__item" key={item.index} >
      <div className="slider_banner__item_img"
        style={{ backgroundImage: `url(${item.image})`, backgroundColor: item.backgroundColour}}
        onClick={() => {
          if (!isSwipe && item?.redirect && item.redirect !== ""){
             gotoEvent(item.redirect);
          }

          // if(auth) {
          //   if(!isSwipe)  gotoEvent(item.redirect)
          // } else {
          //   dispatch(setLoginNew(true))
          // }
        }
      }/>
    </div>)
  })

  return (
    <Slider onSwipe={swipeHandler} {...settings}>
      {bannerList}
    </Slider>
  );
}


export default TopBannerSlider
