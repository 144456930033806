import { IonButton, IonIcon } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import './SportButton.scss'
import { connectSportButton } from './SportButton.connect'

const SportButton = ({itemButton:{ name, icon, redirect, backgroundImage, backgroundColour, id }, auth, actions, elementsParams, menu}) => {
  const history = useHistory()

  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  const gotoEvent = () => {
    console.log('menuButton', menu);
    console.log('menuID', name);
      if(menu.filter(item => item.name === name).length){
    actions.setIsNavigationOpen(true)
    setTimeout(() => {
      actions.setIsNavigationOpen(false)
    }, 400)
    setTimeout(() => {
      actions.setIsAnimatedMenu(false)
    }, 400)
    actions.setIsNavigationID(redirect.split('/')[2])

    actions.setIsNavigationName(name);
    if (width > 1024) {
      history.push(redirect.split('/',2).join('/'))
    } else {
      history.push(redirect.replace(redirect.split('/',2)[1], "menu-event"))
    }
      }
    // if(auth) {
    //   actions.setIsNavigationOpen(true)
    //   setTimeout(() => {
    //     actions.setIsNavigationOpen(false)
    //   }, 400)
    //   setTimeout(() => {
    //     actions.setIsAnimatedMenu(false)
    //   }, 400)
    //   actions.setIsNavigationID(redirect.split('/')[2])
    //   actions.setIsNavigationName(name)
    //   history.push(redirect.split('/',2).join('/'))
    // }
    // else {
    //   actions.setLoginNew(true)
    // }
  }
  return (
    <>
      <IonButton className="sport-button" mode="md" id={id}>
        <div className="sport-button__content" onClick={gotoEvent} style={{backgroundColor: `${backgroundColour}`}}>
          <img className="sport-button__background-img" src={backgroundImage} alt="" />
          <div className={`sport-button__content-text`}>
            <img src={icon} alt="" />
            <span>{name}</span>
          </div>
        </div>
      </IonButton>
    </>

  )
}

export default connectSportButton()(SportButton)
