import './RightSideMenu.scss'
import React from 'react'
import { IonContent, IonItem, IonLabel, IonList, IonMenu } from '@ionic/react'
import { useDispatch, useSelector } from 'react-redux'
import { connectRightSideMenu } from './RightSideMenu.connect'
import { useHistory } from 'react-router'
import { strict } from 'assert'
import { useState, useEffect } from 'react'
import OneClickToggle from '../OneClickToggle'
import gift from '../../assets/images/menu-icons/gift1.svg'
import { setBonusPopUp, setWalletType } from '../../store/bonusPopUp/bonusPopUp.slice'
import signMob from '../../assets/images/signMob.svg'
import { showDeposit } from "../../helpers/showDeposit.helper";

const menuItems = [
  {
    glyph: '',
    text: 'Transactions',
    to: '/transactions',
    iconClass: ''
  },
  {
    glyph: '',
    text: 'Open Bets',
    to: '/my-open-bets',
    // iconClass: 'open-bets menu-icon',
  },
  {
    glyph: '',
    text: 'Betting Profit & Loss',
    to: '/betting_profit_and_loss',
    // iconClass: 'betting_profit menu-icon',
  },
  {
    // glyph: '',
    glyph: '',
    text: 'Account Statement',
    to: '/account_statement',
    iconClass: 'account-statement menu-icon'
  },
  {
    // glyph: '',
    glyph: '',
    text: 'Bonus Statement',
    to: '/bonus_statement',
    iconClass: 'bonus-statement menu-icon'
    },
  /*   {
    glyph: '',
    text: 'Transaction',
    to: '/transaction_popup',
  }, */
  {
    glyph: '',
    text: 'Transfer Statement',
    to: '/transfer_statement',
    // iconClass: 'transfer menu-icon',
  },
  {
    glyph: '',
    text: 'Time Setting',
    to: '/time_settings',
    // iconClass: 'time new menu-icon',
  },
  {
    glyph: '',
    text: 'Change Password',
    to: '/change_password',
    // iconClass: 'change-password menu-icon',
  },
  {
    glyph: '',
    text: 'Rules & Regulations ',
    to: '/rules_regs',
    // iconClass: 'rules menu-icon',
  },
  {
    glyph: '',
    text: 'Settings',
    to: '/settings',
    // iconClass: 'settings menu-icon',
  },
  // {
  //   glyph: '',
  //   text: 'KYC',
  //   to: '/kyc',
  //   // onClick: (actions) => {
  //   //   actions.setKycPopoverShow(true);
  //   // }
  // },
  {
    // glyph: '',
    glyph: '',
    text: 'Exclusion Policy',
    to: '/exclusion_policy',
    iconClass: 'policy menu-icon',
  },
  {
    // glyph: '',
    glyph: '',
    text: 'Responsible Gambling',
    to: '/responsible_gambling',
    iconClass: 'responsible_gambling menu-icon',
  },
  // todo need check
  {
    // glyph: '',
    // glyph: '',
    text: 'Privacy policy',
    to: '/privacy_policy',
    iconClass: 'privacy-policy menu-icon',
  },
]

const RightSideMenu = ({ balance, B2C, user, actions, auth }) => {
  const history = useHistory()
  // const B2C = useSelector(state => state.B2C)
  const [bonusToggle, setBonusToggle] = useState(balance?.useBWallet ? balance?.useBWallet : false)
  const [sportsBonusToggle, setSportsBonusToggle] = useState(balance?.useSWallet ? balance?.useSWallet : false)
  const [casinosBonusToggle, setCasinoBonusToggle] = useState(balance?.useCWallet ? balance?.useCWallet : false)
  const dispatch = useDispatch()

  const changeBonusToggle = (bool) => {
    setBonusToggle(bool)
    actions.changeWalletStatus(user.memberCode, bool, "B")
  }

  const changeSportsBonusToggle = (bool) => {
    setSportsBonusToggle(bool)
    actions.changeWalletStatus(user.memberCode, bool, "S")
  }

  const changeCasinoBonusToggle = (bool) => {
    setCasinoBonusToggle(bool)
    actions.changeWalletStatus(user.memberCode, bool, "C")
  }

  useEffect(() => {
    setBonusToggle(balance?.useBWallet)
    setSportsBonusToggle(balance?.useSWallet)
    setCasinoBonusToggle(balance?.useCWallet)

  }, [balance.useBWallet, balance.useSWallet, balance.useCWallet])

  const getMenuItems = () => {
    let result
    if(auth) {
      if ((B2C || !localStorage.getItem('B2C') === true)&& showDeposit()) {
        result = menuItems
      } else result = menuItems.filter(item => item.text !== 'Transactions' && item.text !== 'Bonus Statement' )
    } else {
      let filtered = ['Transactions', 'Open Bets', 'Betting Profit & Loss', 'Account Statement',  'Transfer Statement', 'Change Password', "Settings"]

      result = menuItems.filter(item => !filtered.includes(item.text))
    }

    return result.map((value, index) => {
      return (
        <IonItem
          className="right-side-menu__item"
          key={index}
          onClick={(e) => {
            if (value.to) {
              history.push(value.to)
              // console.log('value.to', value.to)
            } else if (value.onClick) {
              value.onClick(actions)
            }
          }}
        >
           <span className={`right-side-menu__item__icon ${value?.iconClass}`}>{value.glyph}</span>
          <IonLabel className="right-side-menu__item__label">
            {value.text}
          </IonLabel>
        </IonItem>
      )
    })
  }

  const renderMenuItem = (
    glyph,
    text,
    additionalClass = '',
    action = () => {
    },
    withButton = false,
  ) => {

    return (
      <IonItem
        className={`right-side-menu__item ${additionalClass}`}
        onClick={action}
      >
        {glyph.length > 1 ? (
          <div className="right-side-menu__item__icon-wrapper">
            <img src={glyph} className="right-side-menu__item__icon" />
          </div>
        ) : (
          <span className="right-side-menu__item__icon">{glyph}</span>
        )
        }
        <IonLabel className="right-side-menu__item__label">{text}</IonLabel>
        {withButton && <div onClick={action} className={additionalClass + ' popUpButton'}>i</div>}
      </IonItem>
    )
  }
  // console.log("gift", gift.length)
  const renderAccountInfoItem = (text, value, color, valueClass = '') => {
    let additionalClass = ''
    if (color) {
      if (value > 0)
        additionalClass = 'right-side-menu__account__info__item__value--green'
      if (value < 0)
        additionalClass = 'right-side-menu__account__info__item__value--red'
    }
    return (
      <div className="right-side-menu__account__info__item">
        <span className="right-side-menu__account__info__item__label">
          {text}
        </span>
        <span
          className={`right-side-menu__account__info__item__value ${additionalClass} ${valueClass}`}
        >
          {value?.toLocaleString('en-US', { minimumFractionDigits: 2 })}
        </span>
      </div>
    )
  }

  return (
    <IonMenu
      side="end"
      id="LeftSide"
      menuId="LeftSide"
      contentId="LeftSide"
      swipeGesture={false}
      type="push"
      className="right-side-menu"
    >
      <IonContent>
        <div className="right-side-menu__top">
        {auth ? (
          <>
          <div className="right-side-menu__username">
            {user &&
              user.loginName &&
              (user.loginName.includes('.')
                ? user.loginName.split('.')[1]
                : user.loginName)}
          </div>
          <div className="right-side-menu__account">
            {renderMenuItem(
              '',
              'Balance Information',
              'right-side-menu__item--balance',
            )}
            <div className="right-side-menu__account__info">
              {renderAccountInfoItem(
                'Available Credit:',
                balance && balance.availBalance,
                false,
                'bold',
              )}
              {!B2C && renderAccountInfoItem(
                'Credit Limit:',
                balance && balance.creditLimit,
              )}
              {!B2C && renderAccountInfoItem(
                'Winnings:',
                balance && balance.totalBalance,
                true,
                balance && balance.totalBalance > 0 ? 'right-side-menu__account__info__item__value--green' : '',
              )}
              {renderAccountInfoItem(
                'Net Exposure:',
                balance && balance.deductedExposure,
                true,
                balance && balance.deductedExposure < 0 ? 'right-side-menu__account__info__item__value--red' : '',
              )}
            </div>
          </div>
          {(B2C || !localStorage.getItem('B2C') === "true") && showDeposit() && (
            <div className="right-side-menu__depositButtons">
              <button
                className="right-side-menu__depositButtons-dep"
                onClick={() => history.push('/deposit')}
              >
                Deposit
              </button>
              <button
                className="right-side-menu__depositButtons-with"
                onClick={() => history.push('/withdrawal')}
              >
                Withdrawal
              </button>
            </div>
          )}

          {(B2C || !localStorage.getItem('B2C') === "true")  && (
            <>
              {/*Bonus wallet => B */}
              {(balance?.bWallet) && <div className="right-side-menu__account" onClick={() => dispatch(setWalletType('B'))}>
                {renderMenuItem(
                  gift,
                  'Bonus Information',
                  'right-side-menu__item--balance',
                  () => dispatch(setBonusPopUp(true)),
                  'true',
                )}
                <div className="right-side-menu__account__info">
                  {renderAccountInfoItem(
                    'Available Credits:',
                    balance?.bWallet?.availBalance ? balance.bWallet.availBalance : 0,
                    false,
                    'bold',
                  )}
                  {renderAccountInfoItem(
                    'Net Exposure:',
                    balance?.bWallet?.deductedExposure ? balance.bWallet.deductedExposure : 0,
                    true,
                    balance?.bWallet?.deductedExposure < 0 ? 'right-side-menu__account__info__item__value--red' : '',
                  )}
                </div>
                <div className="newTogglerBonusMenu">
                  <span className="newTogglerBonusMenu__oneClick">
                    Play with Bonus
                  </span>
                  <label className="switcher">
                    <input
                      type="checkbox"
                      checked={bonusToggle}
                      onClick={() => changeBonusToggle(!bonusToggle)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>}
              {/*Sports bonus wallet => S */}
              {(balance?.sWallet) && <div className="right-side-menu__account" onClick={() => dispatch(setWalletType('S'))}>
                {renderMenuItem(
                  gift,
                  'Sports Bonus',
                  'right-side-menu__item--balance',
                  () => dispatch(setBonusPopUp(true)),
                  'true',
                )}
                <div className="right-side-menu__account__info">
                  {renderAccountInfoItem(
                    'Available Credits:',
                    balance?.sWallet?.availBalance ? balance.sWallet.availBalance : 0,
                    false,
                    'bold',
                  )}
                  {renderAccountInfoItem(
                    'Net Exposure:',
                    balance?.sWallet?.deductedExposure ? balance.sWallet.deductedExposure : 0,
                    true,
                    balance?.sWallet?.deductedExposure < 0 ? 'right-side-menu__account__info__item__value--red' : '',
                  )}
                </div>
                <div className="newTogglerBonusMenu">
                  <span className="newTogglerBonusMenu__oneClick">
                  Play with Bonus
                  </span>
                  <label className="switcher">
                    <input
                      type="checkbox"
                      checked={sportsBonusToggle}
                      onClick={() => changeSportsBonusToggle(!sportsBonusToggle)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>}
              {/*Casino bonus wallet => C*/}
              {(balance?.cWallet) && <div className="right-side-menu__account" onClick={() => dispatch(setWalletType('C'))}>
                {renderMenuItem(
                  gift,
                  'Casino bonus',
                  'right-side-menu__item--balance',
                  () => dispatch(setBonusPopUp(true)),
                  'true',
                )}
                <div className="right-side-menu__account__info">
                  {renderAccountInfoItem(
                    'Available Credits:',
                    balance?.cWallet?.availBalance ? balance.cWallet.availBalance : 0,
                    false,
                    'bold',
                  )}
                  {renderAccountInfoItem(
                    'Net Exposure:',
                    balance?.cWallet?.deductedExposure ? balance.cWallet.deductedExposure : 0,
                    true,
                    balance?.cWallet?.deductedExposure < 0 ? 'right-side-menu__account__info__item__value--red' : '',
                  )}
                </div>
                <div className="newTogglerBonusMenu">
                  <span className="newTogglerBonusMenu__oneClick">
                  Play with Bonus
                  </span>
                  <label className="switcher">
                    <input
                      type="checkbox"
                      checked={casinosBonusToggle}
                      onClick={() => changeCasinoBonusToggle(!casinosBonusToggle)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>}
            </>
          )}
          {/*{(B2C || !localStorage.getItem('B2C') === "true") && showDeposit() && (*/}
          {/*  <div className="right-side-menu__depositButtons">*/}
          {/*    <button*/}
          {/*      className="right-side-menu__depositButtons-dep"*/}
          {/*      onClick={() => history.push('/deposit')}*/}
          {/*    >*/}
          {/*      Deposit*/}
          {/*    </button>*/}
          {/*    <button*/}
          {/*      className="right-side-menu__depositButtons-with"*/}
          {/*      onClick={() => history.push('/withdrawal')}*/}
          {/*    >*/}
          {/*      Withdrawal*/}
          {/*    </button>*/}
          {/*  </div>*/}
          {/*)}*/}
          </>
          ): (
            <>
            <div className='login'>
              <button
                className="BetPlacing__btn BetPlacing__btn--place nb"
                expand="full"
                mode="md"
                onClick={() => {
                  actions.setLoginNew(true)
                  history.push('/default-page')
                }}
              >
                <div className="BetPlacing__col">
                  <div>Log In</div>
                </div>
            </button>
            </div>

            </>
            )}
            <IonList className="right-side-menu__list">{getMenuItems()}</IonList>
            </div>
            {auth ? (
              <>
                {renderMenuItem(
                  '',
                  'Sign Out',
                  'right-side-menu__item--bottom',
                  actions.requestLogout,
                )}
              </>
            ) : (
              <>
                {renderMenuItem(
                  `${signMob}`,
                  'Sign Up',
                  'right-side-menu__item--bottom',
                  () => window.location.href ='https://www.lotus365.com/?sign',
                )}
              </>
            )}
      </IonContent>
    </IonMenu>
  )
}

export default connectRightSideMenu()(RightSideMenu)
