export const showDeposit = () =>{
  try {
    let userData = JSON.parse(localStorage.getItem('user_data'))
    let memberCode = userData.memberCode
    // console.log(memberCode.substring(2,6) === '0101','user')
    if(memberCode.substring(2,6) === '0101' ||
      // memberCode.substring(2,6) === '0102' ||
      memberCode.substring(2,6) === '0105'
      // memberCode.substring(2,6).startsWith("01Y") ||
      // memberCode.substring(2,6).startsWith("01Z")
    )
      return true;
    else return false;
  }
  catch (e) {
    return false;
  }

  return false;
}
