import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider } from 'react-redux'
import store from './store/store'

import { init, Replay } from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import ReduxToastr from 'react-redux-toastr'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'

// TODO: add correct link for production Sentry

// process.env.NODE_ENV === 'production' &&
  init({
    dsn: "https://db11cf3aa98d4196b2036ad0e6bb47a1@o1113431.ingest.sentry.io/6310735",
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [new Integrations.BrowserTracing(), new Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: ['ResizeObserver loop limit exceeded', "Non-Error exception captured",
    "Non-Error promise rejection captured"]
  })

ReactDOM.render(
  <Provider store={store}>
    <App />
    <ReduxToastr
    timeOut={3400}
    closeOnToastrClick={false}
    position="top-center"
    transitionIn="bounceInDown"
    transitionOut="bounceOutUp"
  />
  </Provider>,
  document.getElementById('root'),


)

serviceWorkerRegistration.register();
