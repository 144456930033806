import httpAuth from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getCasinoLiveError, getCasinoLiveSuccess } from './casinoLive.slice'

export const requestCasinoStart = (id = '1444000', type = "LIVE_CASINO") => async (dispatch) => {
  try {
    const response = await httpAuth.post(`/casino/live/session/open`, { id, type })
    if (response.data.success) {
      dispatch(getCasinoLiveSuccess(response.data.result))
    }
    return response
  } catch (e) {
    dispatch(getCasinoLiveError())
    // const errorMsg = getErrorMessage(e)
    // throw errorMsg
  }
}

export const requestCasinoEnd = () => async (dispatch) => {
  try {
    const response = await httpAuth.post(`/casino/live/session/close`)
    if (response.data.success) {
      dispatch(getCasinoLiveSuccess(response.data.result))
    }
    return response
  } catch (e) {
    dispatch(getCasinoLiveError())
    // const errorMsg = getErrorMessage(e)
    // throw errorMsg
  }
}
